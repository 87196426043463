import Nav from "../Nav/Nav";

import "./Layout.css"

export default function Layout(props) {
 
  return (
    <div className="layout">
       <Nav />
    
    </div>

  );
}